import { Link } from "gatsby";
import * as React from "react";
import Container from "../../components/container";


// import { StaticImage } from "gatsby-plugin-image";
//import HubspotForm from "react-hubspot-form";
import {
  mainContent,
  //introSection,
  //description,
  row,
  //col6,
  col12,
  marginTopSubstitute,
  //hubSpotForm,
} from "../subpage.module.scss";

const BestaetigungPage = () => {
  return (
    
      <main className={mainContent}>
        <section>
        <Container oneColumn={true}>
            <div className={row}>
              <div className={col12}>

                <h1>Vielen Dank für Ihre Anmeldung.</h1>
                <p className="text-medium">
                Eine Bestätigung via E-Mail ist bereits zu Ihnen unterwegs. Wir freuen uns über Ihre Teilnahme.
                </p>
                <p>* Sollte die E-Mail nach 15 Minuten nicht eingetroffen sein, schauen Sie bitte in Ihrem Spam-, Bulk- oder Junk Mail Ordner nach. 
                  Bei Fragen können Sie uns gerne auch eine E-Mail schreiben.</p>

                  <h3 className={marginTopSubstitute}>
                  the i-engineers AG verpflichtet sich, Ihre Privatsphäre zu schützen und zu respektieren. </h3>
                  
                  <p>Wir verwenden Ihre persönlichen Daten nur zur Verwaltung Ihres Kontos und 
                  zur Bereitstellung der von Ihnen angeforderten Produkte und Dienstleistungen. </p>
                  
                  <p>Von Zeit zu Zeit möchten wir Sie über unsere Produkte und Dienstleistungen sowie andere Inhalte, 
                  die für Sie von Interesse sein könnten, informieren. </p>
                  
                  <p>Weitere Informationen zum Abbestellen, zu unseren Datenschutzverfahren und dazu, wie wir Ihre Privatsphäre 
                  schützen und respektieren, finden Sie in unserer <Link rel="datenschutzerklärung" href="/datenschutz#newsletter-events"> Datenschutzrichtlinien.</Link></p>
                  
                <p>Beste Grüsse, the i-engineers  </p>
              </div>
            </div>



           
          </Container>
        </section>
      </main>
  
  );
};

export default BestaetigungPage;
